/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface IMeta {
  name: string
  content: string
}
interface IProps {
  description?: string
  lang?: string
  meta?: IMeta[]
  title: string
}

const Seo: React.FC<IProps> = ({
  description = "",
  lang = "en",
  meta = [],
  title,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta || [])}
    >
      <link
        rel="stylesheet"
        type="text/css"
        href="https://fonts.googleapis.com/css?family=Prompt:700&display=swap"
      />
      {/* 
        The stylesheet below includes the following fonts: ->
        - Neue Haas Grotesk Display Pro 55 Roman
        - Neue Haas Grotesk Display Pro 56 Italic
        - Neue Haas Grotesk Display Pro 65 Medium
        - Neue Haas Grotesk Display Pro 75 Bold 
      */}
      <link
        rel="stylesheet"
        type="text/css"
        href="https://use.typekit.net/xvg1mnp.css"
      />
    </Helmet>
  )
}

export default Seo
